/*
 * headerfooterintersect - Handles header/footer sticky menu classes using
 * intersect
 */

export const name = 'headerfooterintersect';

export function data() {
  return {
    header: {
      ['x-intersect:enter']() {
        document.body.classList.remove('jsm-past-header');
      },

      ['x-intersect:leave']() {
        document.body.classList.add('jsm-past-header');
      },
    },

    footer: {
      ['x-intersect:enter']() {
        document.body.classList.add('jsm-in-footer');
      },

      ['x-intersect:leave']() {
        document.body.classList.remove('jsm-in-footer');
      },
    },
  };
}
