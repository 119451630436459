// import Alpine from '@alpinejs/csp';
import Alpine from 'alpinejs';
import focus from '@alpinejs/focus';
import intersect from '@alpinejs/intersect';
import persist from '@alpinejs/persist';
import morph from '@alpinejs/morph';

import * as accountmenu from './alpine/global/accountmenu.js';
import * as auth from './alpine/global/auth.js';
import * as headerfooterintersect from './alpine/global/headerfooterintersect.js';
import * as loginForm from './alpine/global/login-form.js';
import * as recentlyViewed from './alpine/global/recently-viewed.js';
import * as share from './alpine/global/share.js';
import * as sitemessages from './alpine/global/sitemessages.js';
import * as tabs from './alpine/global/tabs.js';
import * as toasts from './alpine/global/toasts.js';

window.Alpine = window.Alpine || Alpine;
window.Hooks = window.Hooks || {};

if (!window.alpineStarted) {
  /**************************************
   * Plugins                            *
   **************************************/

  // window.Alpine.plugin(…);
  //
  window.Alpine.plugin(focus);
  window.Alpine.plugin(intersect);
  window.Alpine.plugin(persist);
  window.Alpine.plugin(morph);

  /**************************************
   * Stores                             *
   **************************************/
  // Add global store declarations here. For ones only used in the odd place or
  // two hook them up in a separate script in the alpine folder, and load them
  // only on pages as needed using the `alpine:init` event.

  // window.Alpine.store(…);

  window.Alpine.store(auth.name, auth.store());
  window.Alpine.store(recentlyViewed.name, recentlyViewed.store());
  window.Alpine.store(sitemessages.name, sitemessages.store());
  window.Alpine.store(toasts.name, toasts.store());

  /**************************************
   * Data                               *
   **************************************/

  // Add global data declarations here. For ones only used in the odd place or
  // two hook them up in a separate script in the alpine folder, and load them
  // only on pages as needed using the `alpine:init` event.

  // window.Alpine.data(…);

  window.Alpine.data(accountmenu.name, accountmenu.data);
  window.Alpine.data(headerfooterintersect.name, headerfooterintersect.data);
  window.Alpine.data(loginForm.name, loginForm.data);
  window.Alpine.data(recentlyViewed.name, recentlyViewed.data);
  window.Alpine.data(share.name, share.data);
  window.Alpine.data(sitemessages.name, sitemessages.data);
  window.Alpine.data(tabs.name, tabs.data);
  window.Alpine.data(toasts.name, toasts.data);

  /**************************************
   * Initialise                         *
   **************************************/
  // We use this alpineStarted attribute as a guard against running twice.
  // When using hx-boost for links and forms etc. our JS will be re-evaluated
  // when the <body> content is swapped. We could not include the JS in requests
  // with the HX-Request header but that would break and/or complicate caching.
  // This is easier and more reliable.
  window.Alpine.start();
  window.alpineStarted = true;
}
