/*
 * auth - Auth store for user information
 */
import {camelCase, isObject, mapKeys} from 'lodash-es';

import {checkStatus} from '../../fetch.js';

export const name = 'auth';

const defaultUser = {
  id: null,
  is_authenticated: false,
  is_guest: null,
  open_order_count: 0,
  ending_subscription_count: 0,
  has_subscription_account: null,
  token: null,
  credit: null,
  notifications: [],
  affiliate_code: '',
  is_promotion_admin: false,
  email: null,
  title: null,
  first_name: null,
  last_name: null,
  display_name: null,
  contact_number: null,
  last_login: null,
  date_joined: null,
  allow_listing_buy: true,
};

function camelKeys(obj) {
  if (isObject(obj)) {
    return mapKeys(obj, (value, key) => camelCase(key));
  }
  return obj;
}

export function store() {
  return {
    user: undefined,

    init() {
      document.addEventListener('login', this.loginEventHandler.bind(this));
      document.addEventListener('logout', this.logoutEventHandler.bind(this));
      this.user = camelKeys({...defaultUser});
    },

    loginEventHandler(ev) {
      this.user = camelKeys({
        ...defaultUser,
        ...{is_authenticated: true},
        ...ev.detail,
      });
    },

    logoutEventHandler(ev) {
      this.user = camelKeys({
        ...defaultUser,
        ...{is_authenticated: false},
        ...ev.detail,
      });
    },

    async refresh() {
      try {
        const response = await fetch('/api/whoami/');
        checkStatus(response);
        const user = await response.json();
        this.user = camelKeys({...defaultUser, ...user});
        return this.user;
      } catch (e) {
        return null;
      }
    },
  };
}
