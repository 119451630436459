/*
 * toasts - Provides toast message functionality. Call window.Hooks.showToast with
 * either a message, or a config object containing one or all of msg, level
 * (info, warn, error, success), timeout, dismissable, and tag fields. Pass a
 * function as the undo tag to show an Undo button that calls the tag.
 *
 * Example markup:
 *
 *   <div x-data="toasts" x-show="hasToasts" x-transition.duration.500ms>
 *     <ul class="toast-list">
 *       <template x-for="toast in toasts" :key="toast.id" x-transition.duration.500ms>
 *         <li class="toast" :class="toast.level">
 *           <div class="toast__message" x-text="toast.msg"></div>
 *           <template x-if="toast.hasUndo">
 *             <button class="toast__undo" title="Undo" x-on:click="callUndo" :data-toast-id="toast.id">
 *               Undo
 *             </button>
 *           </template>
 *           <template x-if="toast.dismissable">
 *             <button class="toast__dismiss" title="Dismiss" x-on:click="dismissOnClick" :data-toast-id="toast.id">
 *               <i class="icon icon-27"></i>
 *             </button>
 *           </template>
 *         </li>
 *       </template>
 *     </ul>
 *   </div>
 */

import {isFunction} from 'lodash-es';

export const name = 'toasts';

export function store() {
  return {
    toasts: [],
    currentId: 0,

    init() {
      document.documentElement.addEventListener('toast', (ev) => {
        this.currentId++;
        let toast = {
          id: `toast-${this.currentId}`,
          tag: null,
          msg: '',
          level: 'info',
          timeout: 10,
          dismissable: true,
          undo: null,
        };
        if (typeof ev.detail === 'string') {
          toast.msg = ev.detail;
        } else {
          toast = {...toast, ...ev.detail};
        }

        toast.dismiss = () => this.dismiss(toast.id);
        toast.hasUndo = isFunction(toast.undo);

        const toasts = this.toasts.filter(
          (existing) => existing.tag === null || existing.tag !== toast.tag
        );
        toasts.push(toast);
        this.toasts = toasts;

        if (toast.timeout > 0) {
          toast._timeout = window.setTimeout(
            toast.dismiss,
            toast.timeout * 1000
          );
        }
      });

      window.Hooks.showToast = (msgOrConfig) => {
        const evt = new CustomEvent('toast', {
          detail: msgOrConfig,
          bubbles: true,
        });
        document.documentElement.dispatchEvent(evt);
      };
    },

    dismiss(toastId) {
      const toast = this.toasts.find((toast) => toast.id === toastId);
      if (toast) {
        window.clearTimeout(toast._timeout);
      }
      this.toasts = this.toasts.filter((toast) => toast.id !== toastId);
    },
  };
}

export function data() {
  return {
    get toasts() {
      return this.$store.toasts.toasts;
    },

    get hasToasts() {
      return this.$store.toasts.toasts.length > 0;
    },

    dismissOnClick() {
      this.$store.toasts.dismiss(this.$el.dataset.toastId);
    },

    callUndo() {
      const toast = this.toasts.find(
        (toast) => toast.id == this.$el.dataset.toastId
      );
      if (toast) {
        if (isFunction(toast.undo)) {
          toast.undo();
        }
        this.$store.toasts.dismiss(toast.id);
      }
    },
  };
}
