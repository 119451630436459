/*
 * tabs - Simple tab interface
 *
 * Example markup:
 *
 *  <div x-data="tabs">
 *    <ul x-bind="tablist">
 *      <li><a x-bind="label" href="#tab1">Tab 1</a></li>
 *      <li><a x-bind="label" href="#tab2">Tab 2</a></li>
 *      <li><a x-bind="label" href="#tab3">Tab 3</a></li>
 *    </ul>
 *    <div x-bind="tab" id="tab1">Tab 1 Content</div>
 *    <div x-bind="tab" id="tab2">Tab 2 Content</div>
 *    <div x-bind="tab" id="tab3">Tab 3 Content</div>
 *  </div>
 *
 *  Or using data-* instead of href/id:
 *
 *  <div x-data="tabs">
 *    <ul x-cloak x-bind="tablist">
 *      <li><button x-bind="label" data-tab="tab1">Tab 1</button></li>
 *      <li><button x-bind="label" data-tab="tab2">Tab 2</button></li>
 *      <li><button x-bind="label" data-tab="tab3">Tab 3</button></li>
 *    </ul>
 *    <div x-bind="tab" data-tab="tab1">Tab 1 Content</div>
 *    <div x-bind="tab" data-tab="tab2">Tab 2 Content</div>
 *    <div x-bind="tab" data-tab="tab3">Tab 3 Content</div>
 *  </div>
 */

/* Polyfill Array.prototype.at for Safari */
import "core-js/proposals/relative-indexing-method";

export const name = "tabs";

export function data() {
  return {
    active: null,

    get labels() {
      return Array.prototype.map.call(
        this.$root.querySelectorAll('[x-bind="tab"]'),
        (elem) => {
          return elem.dataset.tab || elem.id;
        },
      );
    },

    init() {
      this.active = this.labels.at(0);
      if (!this.$root.querySelector('[role="tablist"],[x-bind="tablist"]')) {
        this.$root.setAttribute("role", "tablist");
      }
    },

    tablist: {
      ["x-init"]() {
        if (!this.$el.hasAttribute("role")) {
          this.$el.setAttribute("role", "tablist");
        }
      },

      ["@keyup.left"]() {
        this.active = this.labels.at(this.labels.indexOf(this.active) - 1);
      },

      ["@keyup.right"]() {
        this.active = this.labels.at(this.labels.indexOf(this.active) + 1);
      },

      ["@keyup.home.stop.prevent"]() {
        this.active = this.labels.at(0);
      },

      ["@keyup.end.stop.prevent"]() {
        this.active = this.labels.at(-1);
      },
    },

    tabIdFromLabelElement(el) {
      return el.dataset.tab || el.getAttribute("href", "").split("#").at(-1);
    },

    label: {
      ["x-init"]() {
        const tabId = this.tabIdFromLabelElement(this.$el);
        const tab = this.$root.querySelector(`[data-tab="${tabId}"],#${tabId}`);

        if (!this.$el.hasAttribute("role")) {
          this.$el.setAttribute("role", "tab");
        }
        if (!this.$el.hasAttribute("tabindex")) {
          this.$el.setAttribute("tabindex", -1);
        }
        if (!this.$el.hasAttribute("aria-controls")) {
          if (tab && tab.id) {
            this.$el.setAttribute("aria-controls", tab.id);
          }
        }
        if (this.$el.dataset.selected) {
          this.active = tabId;
          delete this.$el.dataset.selected;
        }
      },

      [":class"]() {
        try {
          if (this.active === this.tabIdFromLabelElement(this.$el)) {
            return "active";
          }
        } catch (e) {
          // pass
        }
        return "";
      },

      [":aria-selected"]() {
        try {
          if (this.active === this.tabIdFromLabelElement(this.$el)) {
            return true;
          }
        } catch (e) {
          // pass
        }
        return false;
      },

      ["@click.prevent"]() {
        try {
          this.active = this.tabIdFromLabelElement(this.$el);
        } catch (e) {
          // pass
        }
      },

      ["@keyup.space.prevent.stop"]() {
        this.active = this.tabIdFromLabelElement(this.$el);
      },

      ["@keyup.enter"]() {
        this.active = this.tabIdFromLabelElement(this.$el);
      },
    },

    tabIdFromTabElement(el) {
      return el.dataset.tab || el.id;
    },

    tab: {
      ["x-init"]() {
        const tabId = this.tabIdFromTabElement(this.$el);
        const label = this.$root.querySelector(
          `[data-tab="${tabId}"],[href="#${tabId}"]`,
        );

        if (!this.$el.hasAttribute("role")) {
          this.$el.setAttribute("role", "tabpanel");
        }
        if (!this.$el.hasAttribute("aria-labelledby")) {
          if (label) {
            if (!label.id) {
              label.id = `tab_label__${tabId}`;
            }
            this.$el.setAttribute("aria-labelledby", label.id);
          }
        }
        if (this.$el.dataset.selected) {
          this.active = this.tabId;
          delete this.$el.dataset.selected;
        }
      },

      ["x-show"]() {
        return this.active === this.tabIdFromTabElement(this.$el);
      },
    },
  };
}
