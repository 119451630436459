/*
 * login-form - Global login form.
 * Open by sending a `login:required` event.
 *
 * Will trigger `login:aborted` if closed before login. Otherwise will trigger
 * `login:failed` or `login` via the backend.
 *
 * To set a message send `purpose` in evt.detail to display
 *   "Login to ${purpose}"
 * or `message` to set the raw message.
 */

export const name = 'loginForm';

export function data() {
  return {
    isOpen: false,
    message: '',
    defaultMessage: 'You need to login to do that',

    init() {
      this.$root.addEventListener('close', () => (this.isOpen = false));
      document.documentElement.addEventListener(
        'login',
        this.loginHandler.bind(this)
      );
      document.documentElement.addEventListener(
        'login:aborted',
        this.loginAbortedHandler.bind(this)
      );
      document.documentElement.addEventListener(
        'login:required',
        this.loginRequiredHandler.bind(this)
      );
      window.Hooks.loginRequired = (config) => {
        const evt = new CustomEvent('login:required', {
          detail: config,
          bubbles: true,
        });
        document.documentElement.dispatchEvent(evt);
      };
    },

    abortLogin() {
      const evt = new CustomEvent('login:aborted', {bubbles: true});
      this.$root.dispatchEvent(evt);
    },

    loginHandler() {
      this.$root.close();
      this.isOpen = false;
      this.message = '';
      window.Hooks.showToast({
        tag: 'login-form',
        msg: 'Welcome back!',
        level: 'success',
        timeout: 5,
      });
    },

    loginAbortedHandler() {
      this.$root.close();
      this.isOpen = false;
      this.message = '';
    },

    loginRequiredHandler(evt) {
      if (evt.detail && evt.detail.message) {
        this.message = evt.detail.message;
      } else if (evt.detail && evt.detail.purpose) {
        this.message = `You need to login to ${evt.detail.purpose}`;
      } else {
        this.message = this.defaultMessage;
      }
      this.isOpen = true;
      this.$nextTick(() => this.$root.showModal());
    },

    container: {
      ['x-show']() {
        return this.isOpen;
      },

      ['@click.outside']() {
        if (this.isOpen || this.$root.open) {
          this.abortLogin();
        }
      },

      ['x-trap.inert.noscroll']() {
        return this.isOpen;
      },

      ['@keyup.escape.window']() {
        if (this.isOpen || this.$root.open) {
          this.abortLogin();
        }
      },
    },

    closeButton: {
      ['@click']() {
        if (this.isOpen || this.$root.open) {
          this.abortLogin();
        }
      },
    },
  };
}
