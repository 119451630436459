// A simple check that the status of a fetch() response is not an error
// EXAMPLE:
//   async myFunc() {
//   const response = await fetch('/foo/bar/');
//     checkStatus(response);
//     const user = await response.json();
//     return user;
//   }

export function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}
