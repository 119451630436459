/*
 * sitemessages - Handles showing and dismissing site messages
 */

export const name = 'sitemessages';

export function store() {
  return {
    dismissedMessages: window.Alpine.$persist([]).as(
      `${name}__dismissedMessages`
    ),
  };
}

export function data() {
  return {
    init() {
      this.messageId = this.$root.dataset.messageId;
    },

    isNotDismissed() {
      return this.$store[name].dismissedMessages.indexOf(this.messageId) === -1;
    },

    dismiss() {
      this.$store[name].dismissedMessages.push(this.messageId);
    },
  };
}
