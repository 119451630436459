/*
 * recentlyViewed - Handles recently viewed product storage
 *
 */
export const name = 'recentlyViewed';

const MAX_PRODUCTS = 20;

export function store() {
  return {
    // We would use a Set but $persist only works on primitives
    products: window.Alpine.$persist([]).as('recentlyViewedProducts'),

    addProduct(product) {
      const products = this.products.filter((val) => val.id !== product.id);
      products.unshift(product);
      this.products = products.slice(0, MAX_PRODUCTS);
    },

    removeProduct(product) {
      this.products = this.products.filter(
        (val) => val.id !== (Number.isFinite(product) ? product : product.id),
      );
    },
  };
}

export function data() {
  return {
    get products() {
      return this.$store[name].products.filter(
        (product) =>
          Number.isFinite(product.id) &&
          product.url &&
          product.title &&
          product.htmxImageUrl,
      );
    },

    recentlyViewedProductContainer: {
      ['x-show']() {
        return this.products.length > 0;
      },
    },

    recentlyViewedProductList: {
      [':id']() {
        return this.$id('recently-viewed-products');
      },
    },

    recentlyViewedProduct: {
      [':id']() {
        return this.$id('recently-viewed-products', this.$el.dataset.productId);
      },
    },

    recentlyViewedProductImage: {
      ['x-init']() {
        this.$nextTick(() => {
          if (this.$el.getAttribute('hx-get')) {
            window.htmx.process(this.$el);
          }
        });
      },
    },

    recentlyViewedProductListRemove: {
      ['@click.prevent']() {
        const productId = Number.parseInt(this.$el.dataset.productId);
        if (Number.isFinite(productId)) {
          this.$store[name].removeProduct(productId);
        }
      },

      [':aria-label']() {
        const productTitle = this.$el.dataset.productTitle || '';
        return `Remove ${productTitle} from your recently viewed list`;
      },
    },
  };
}
