/*
 * share - Handles share page functionality. Use markup from
 * store/templates/_includes/share.html
 */
export const name = 'share';

export function data() {
  return {
    isOpen: false,
    affiliateId: null,

    get url() {
      const url = new URL(this.$root.dataset.url || window.location);
      url.searchParams.set('utm_medium', 'fp-share');
      if (this.affiliateId) {
        url.searchParams.set('affid', this.affiliateId);
      }
      return url;
    },

    get embedUrl() {
      const baseUrl = new URL(
        `${window.location.protocol}//${window.location.host}`
      );
      baseUrl.pathname = '/oembed/';
      return this.buildShareUrl(baseUrl.toString());
    },

    get embedCode() {
      const embed = document.createElement('blockquote');
      embed.dataset.fpEmbed = this.embedUrl;
      const embedLink = document.createElement('a');
      embedLink.href = this.$el.dataset.embedLink || this.url;
      const embedTitle = document.createElement('p');
      embedTitle.textContent = this.$el.dataset.embedTitle || document.title;
      embedLink.appendChild(embedTitle);
      embed.appendChild(embedLink);
      const script = document.createElement('script');
      script.src = new URL(
        `${window.location.protocol}//${window.location.host}/static/js/embed.min.js`
      );
      return `${embed.outerHTML}${script.outerHTML}`;
    },

    get twitterUrl() {
      return this.buildShareUrl('https://twitter.com/intent/tweet', 'twitter');
    },

    get facebookUrl() {
      return this.buildShareUrl(
        'https://www.facebook.com/sharer/sharer.php',
        'facebook',
        'u'
      );
    },

    get pinterestUrl() {
      return this.buildShareUrl(
        'https://pinterest.com/pin/create/button/',
        'pinterest'
      );
    },

    buildShareUrl(base, source, param = 'url') {
      const url = new URL(base);
      const shareUrl = this.url;
      if (source) {
        shareUrl.searchParams.set('utm_source', source);
      }
      url.searchParams.set(param, shareUrl.toString());
      return url;
    },

    get supportsWebShare() {
      return (
        navigator.canShare && navigator.canShare({url: this.url.toString()})
      );
    },

    async webShare() {
      if (this.supportsWebShare) {
        try {
          await navigator.share({url: this.url.toString()});
        } catch (e) {
          return null;
        }
        this.isOpen = false;
      }
    },

    /* x-bind helpers */
    container: {
      ['@click.outside']() {
        if (this.isOpen) {
          this.isOpen = false;
        }
      },
    },

    widget: {
      ['x-show']() {
        return this.isOpen;
      },

      [':class']() {
        return {
          open: this.isOpen,
        };
      },
    },

    toggle: {
      ['@click.prevent']() {
        this.isOpen = !this.isOpen;
      },
    },

    webShareButton: {
      ['@click.prevent']() {
        this.webShare();
      },
    },

    embedCodeWidget: {
      ['@click.prevent']() {
        this.$el.select();
      },

      ['x-text']() {
        return this.embedCode;
      },

      [':readonly']() {
        return true;
      },
    },

    _copySelected(elem) {
      document.execCommand('copy');
      const oldText = elem.textContent;
      elem.disabled = true;
      elem.textContent = 'Copied…';
      window.setTimeout(() => {
        elem.disabled = false;
        elem.textContent = oldText;
      }, 2500);
    },

    copyEmbedCode: {
      ['@click.prevent']() {
        this.$refs.embedCode.select();
        this._copySelected(this.$el);
      },
    },

    affiliateLinkWidget: {
      ['@click.prevent']() {
        this.$el.select();
      },

      ['x-text']() {
        return this.url;
      },

      [':readonly']() {
        return true;
      },
    },

    copyAffiliateLink: {
      ['@click.prevent']() {
        this.$refs.affiliateLink.select();
        this._copySelected(this.$el);
      },
    },
  };
}
