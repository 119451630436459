/*
 * accountmenu - Handles opening, closing, and manipulating the header account
 * menu
 */

export const name = 'accountmenu';

export function data() {
  return {
    isOpen: false,

    init() {
      this.$watch('isOpen', (value) => {
        if (value) {
          window.htmx.process(this.$root);
        }
      });

      this.$root.addEventListener('htmx:beforeRequest', () => {
        const submit = this.$root.querySelector('button[type="submit"]');
        if (submit) {
          submit.disabled = true;
        }
      });

      this.$root.addEventListener('htmx:afterRequest', () => {
        const submit = this.$root.querySelector('button[type="submit"]');
        if (submit) {
          submit.disabled = false;
        }
      });
    },

    open() {
      this.isOpen = true;
    },

    close() {
      this.isOpen = false;
    },

    toggle() {
      this.isOpen = !this.isOpen;
    },

    container: {
      ['@click.outside']() {
        this.close();
      },
    },

    link: {
      ['@click.prevent']() {
        this.toggle();
      },
    },

    closeButton: {
      ['@click']() {
        if (this.isOpen) {
          this.close();
        }
      },
    },
  };
}
